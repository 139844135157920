import type { FunctionalComponent } from 'preact';

import { cnBlockSlider1 } from '../block-slider-1.constants.js';

import './block-slider-1__arrow-icon.css';

export interface BlockSlider1ArrowIconProps {
    onClick: VoidFunction;
    right?: boolean;
}

export const BlockSlider1ArrowIcon: FunctionalComponent<BlockSlider1ArrowIconProps> = ({ onClick, right }) => (
    <svg
        onClick={onClick}
        className={cnBlockSlider1('arrow-icon', { right })}
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.3335 49.334L32.0001 49.334L32.0001 46.6673L29.3335 46.6673L29.3335 49.334ZM26.6666 46.6675L29.3333 46.6675L29.3333 44.0008L26.6666 44.0008L26.6666 46.6675ZM24.0002 44.0004L26.6668 44.0004L26.6668 41.3337L24.0002 41.3337L24.0002 44.0004ZM21.3334 41.3339L24 41.3339L24 38.6672L21.3334 38.6672L21.3334 41.3339ZM21.3332 38.6674L18.6665 38.6674L18.6665 36.0009L16.0001 36.0009L16.0001 33.3342L18.6667 33.3342L18.6667 36.0007L21.3332 36.0007L21.3332 38.6674ZM48.0001 33.3338L50.6668 33.3338L50.6668 30.6671L48.0001 30.6671L48.0001 33.3338ZM45.3335 33.3338L48 33.3338L48 30.6671L45.3335 30.6671L45.3333 30.6671L42.6668 30.6671L42.6668 33.3338L45.3333 33.3338L45.3335 33.3338ZM40 33.3338L42.6667 33.3338L42.6667 30.6671L40 30.6671L40 33.3338ZM39.9999 33.3338L37.3334 33.3338L37.3332 33.3338L34.6667 33.3338L34.6667 30.6671L37.3332 30.6671L37.3334 30.6671L39.9999 30.6671L39.9999 33.3338ZM34.6666 33.3338L32.0001 33.3338L31.9999 33.3338L29.3335 33.3338L29.3335 30.6671L31.9999 30.6671L32.0001 30.6671L34.6666 30.6671L34.6666 33.3338ZM29.3333 33.3338L26.6668 33.3338L26.6667 33.3338L24.0002 33.3338L24.0002 30.6671L26.6667 30.6671L26.6668 30.6671L29.3333 30.6671L29.3333 33.3338ZM24 33.3338L21.3334 33.3338L21.3334 30.6671L24 30.6671L24 33.3338ZM18.6665 33.3338L21.3332 33.3338L21.3332 30.6671L18.6665 30.6671L18.6665 28.0006L15.9999 28.0006L15.9999 30.6671L13.3333 30.6671L13.3333 33.3338L15.9999 33.3338L15.9999 30.6673L18.6665 30.6673L18.6665 33.3338ZM29.3335 20.0007L26.6668 20.0007L26.6668 17.334L29.3333 17.334L29.3333 14.6676L31.9999 14.6676L31.9999 17.3342L29.3335 17.3342L29.3335 20.0007ZM24.0002 22.6672L26.6667 22.6672L26.6667 20.0005L24 20.0005L24 22.667L21.3335 22.667L21.3335 25.3337L24.0002 25.3337L24.0002 22.6672ZM18.6667 28.0008L21.3334 28.0008L21.3334 25.3341L18.6667 25.3341L18.6667 28.0008Z"
            fill="#1A1A1A"
        />
    </svg>
);
